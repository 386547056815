// TODO ERP3.2 改了原型待废弃
// 已验收设备清单
// popIsCheckedDevice
<template>

    <div class="popIsCheckedDevice">
        <el-dialog title="已验收设备清单"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="1000px"
                   :before-close="close">
            <div class="searchBar">
                <el-form :model="searchForm"
                         ref="searchForm"
                         :rules="searchFormRules"
                         :inline="true">
                    <el-form-item label="设备编号"
                                  prop="test1">
                        <el-input v-model="searchForm.test1"
                                  placeholder="请输入设备编号"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备类型"
                                  prop="test2">
                        <el-select v-model="searchForm.test2"
                                   placeholder="请选择设备类型"
                                   clearable>
                            <el-option v-for="item in testOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态"
                                  prop="test3">
                        <el-select v-model="searchForm.test3"
                                   placeholder="请选择状态"
                                   clearable>
                            <el-option v-for="item in testOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form>
            </div>
            <div class="miniTable">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="50">
                    </el-table-column>
                    <el-table-column prop="table1"
                                     label="设备编号"
                                     align="center"
                                     :min-width="100">
                    </el-table-column>
                    <el-table-column prop="table2"
                                     label="设备类型"
                                     align="center"
                                     :min-width="50">
                    </el-table-column>
                    <el-table-column prop="table3"
                                     label="设备型号"
                                     align="center"
                                     :min-width="50">
                    </el-table-column>
                    <el-table-column prop="table4"
                                     label="状态"
                                     align="center"
                                     :min-width="50">
                    </el-table-column>
                    <el-table-column prop="table5"
                                     label="验收照片"
                                     align="center"
                                     :min-width="50">
                        <template slot-scope="scope">
                            <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row[table5]}&access-token=${token}`)"
                                      style="width: 100px; height: 100px"
                                      fit="scale-down"
                                      :src="`${PATH}/file/view/?file=${scope.row[table5]}&access-token=${token}`"
                                      v-if="scope.row[table5]" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="table6"
                                     label="安装信息"
                                     align="center"
                                     :min-width="50">
                        <!-- TODO ERP3.2 是否有安装信息 -->
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openpopInstallInfo(scope.row)">查看</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </el-dialog>

        <!-- 查看大图 -->
        <el-image-viewer v-if="showViewer"
                         :on-close="closeViewer"
                         :url-list="viewerImgList" />
        <!-- 安装信息 -->
        <pop-install-info v-if="popInstallInfoVisible"
                          :installInfoDetails="installInfoDetails"
                          @close="closepopInstallInfo"></pop-install-info>

    </div>
</template>

<script>
import PopInstallInfo from "../../safeguardManage/components/popInstallInfo";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
    name: "popIsCheckedDevice",

    props: [],

    components: {
        PopInstallInfo,
        ElImageViewer,
    },

    data() {
        return {
            dialogVisible: true,
            searchForm: {
                test1: "", // 设备编号
                test2: "", // 设备类型
                test3: "", // 状态
            },
            searchFormRules: {},
            testOpts: [{ value: "1", label: "测试" }],
            tableData: {
                data: [
                    {
                        table1: "设备编号", // 设备编号
                        table2: "设备类型", // 设备类型
                        table3: "设备型号", // 设备型号
                        table4: "状态", // 状态
                        table5: "1352506657867419649", // 验收照片
                        table6: "安装信息", // 安装信息
                    },
                ],
            },
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),

            showViewer: false, // 缩略图展示框是否显示
            popInstallInfoVisible: false, // 安装信息弹窗显示
            installInfoDetails: null, // 安装信息详情
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        // 点击缩略图的方法
        handleImgClick(url) {
            this.showViewer = true;
            this.viewerImgList = [url];
        },

        // 关闭预览图片
        closeViewer() {
            this.showViewer = false;
        },
        // 安装信息 打开弹框
        openpopInstallInfo(row) {
            this.installInfoDetails = row;
            this.popInstallInfoVisible = true;
        },
        // 安装信息 关闭弹框
        closepopInstallInfo() {
            this.installInfoDetails = null;
            this.popInstallInfoVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>