// 出库记录
<template>

    <div class="comOutHistory">

        <el-card>
            <div slot="header">出库记录</div>
            <div class="miniTable">
                <el-table :data="setupDetailsInfo.outFlowList"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openoutinDeviceList(scope.row)">查看</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>

        <!-- 弹框 -->
        <pop-outin-device-list v-if="outinDeviceListVisible"
                               :row="this.rowDetail"
                               @close="closeoutinDeviceList"></pop-outin-device-list>

    </div>

</template>

<script>
import PopOutinDeviceList from "../../safeguardManage/components/popOutinDeviceList";
export default {
    name: "comOutHistory",

    props: {
        setupDetailsInfo: {
            type: Object,
            default() {
                return {
                    checkedDeviceList: [], // 已验收设备清单
                    modelCountVos: [], // 计划设备清单
                    logList: [], // 施工日志
                    outFlowList: [], // 出库记录
                    rowDetail: {}, // 当前查看详情的出入库记录行信息
                };
            },
        },
    },

    components: {
        PopOutinDeviceList,
    },

    data() {
        return {
            outinDeviceListVisible: false, // 出库设备弹窗显示

            tableData: {
                columns: [
                    { id: "createTime", label: "出库日期", width: "200" },
                    { id: "number", label: "设备数量", width: "100" },
                    { id: "operatorName", label: "出库人", width: "100" },
                    { id: "checkedNum", label: "验收数量", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 出库设备 打开弹框
        openoutinDeviceList(row) {
            this.rowDetail = row;
            this.outinDeviceListVisible = true;
        },
        // 出库设备 关闭弹框
        closeoutinDeviceList() {
            this.outinDeviceListVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>

// 是否上线
.is,
.not {
    position: relative;
    width: 80px;
    margin: 0 auto;
}
.is:after,
.not:after {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    position: absolute;
    background: #67c23a;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.not:after {
    background: #f56c6c;
}
</style>
