// 文件历史记录-时间轴
<template>

    <div class="popFileHistory">
        <el-dialog :close-on-click-modal="false"
                   title="文件历史记录"
                   :visible.sync="dialogVisible"
                   width="40%"
                   :before-close="close">
            <div class="warp"
                 v-if="activities.length>0">
                <el-timeline :reverse="reverse">
                    <el-timeline-item v-for="(item, idx) in activities"
                                      :key="idx">
                        <div class="left">
                            <p class="time">{{item.timestamp}}</p>
                            <p class="uploadUserName">{{item.addUserName}}</p>
                        </div>
                        <div class="right">
                            <ul class="fileList">
                                <li class="fileItem ofh">
                                    <div class="fileName fll ellipsis">
                                        <el-tooltip :content="item.name">
                                            <span>{{item.name}}</span>
                                        </el-tooltip>
                                    </div>
                                    <div class="controlBtn flr">
                                        <span class="el-icon-download"
                                              v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')"
                                              @click="downLoad(item)"></span>
                                        <span class="el-icon-view cursor"
                                              v-if="commonJs.filterIsImg(item.name) || commonJs.filterIsPdf(item.name)"
                                              @click="seeView(item)"></span>
                                        <el-popconfirm confirm-button-text="删除"
                                                       cancel-button-text="取消"
                                                       icon="el-icon-info"
                                                       icon-color="red"
                                                       @confirm="delFile(item)"
                                                       v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_删除文件')"
                                                       title="真的要删除吗？">
                                            <span class="el-icon-delete"
                                                  slot="reference"></span>
                                        </el-popconfirm>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="emptyBg"
                 v-else></div>
        </el-dialog>

        <!-- 图片预览 -->
        <elImageViewer v-if="showViewer"
                       :onClose="showViewerClose"
                       :url-list="[guidePic]"></elImageViewer>

        <!-- pdf预览 -->
        <viewPdf v-if="showPdfView"
                 :pdfUrl="pdfUrl"
                 :title="filename"
                 @close="closeDialog"></viewPdf>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
import ElImageViewer from "element-ui/packages/image/src/image-viewer"; //  图片预览组件
import viewPdf from "@/components/viewPdf/viewPdf"; //  PDF预览组件
export default {
    name: "popFileHistory",

    props: {
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
        fileHistoryType: {
            // 文件type
            type: Object,
            default: null,
        },
        detailRole: {
            type: Object,
        },
    },

    components: {
        elImageViewer: ElImageViewer,
        viewPdf: viewPdf,
    },

    data() {
        return {
            token: this.commonJs.getCookie("ERPToken"),
            loading: false,
            guidePic: null, // 预览图片的地址
            pdfUrl: null, // 预览pdf的地址
            showViewer: false, //大图查看
            showPdfView: false, //pdf查看
            filename: "", //当前查看的文件名称
            fileBoxHeigh: "300px",

            dialogVisible: true,
            reverse: true, // 正序 还是 倒序
            activities: [],
        };
    },

    created() {},

    mounted() {
        // 获取文件列表
        this.getList();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        //查看附件
        seeView(data) {
            if (
                !this.commonJs.filterIsImg(data.name) &&
                !this.commonJs.filterIsPdf(data.name)
            ) {
                return this.$message({
                    message: "当前只支持预览pdf和图片",
                    type: "warning",
                });
            }
            this.filename = data.name;
            /* 为图片时  大图查看 */
            if (this.commonJs.filterIsImg(data.name)) {
                this.showViewer = true;
                this.guidePic = `${window.configPath.baseUrl}/filem/download?id=${data.id}&access-token=${this.token}`;
            } else if (this.commonJs.filterIsPdf(data.name)) {
                /* 为pdf时  大图查看 */
                this.showPdfView = true;
                this.pdfUrl = `${window.configPath.baseUrl}/filem/view?id=${data.id}`;
            }
        },

        //大图查看关闭
        showViewerClose() {
            this.showViewer = false;
        },
        /* 关闭文件预览弹框 */
        closeDialog() {
            this.showPdfView = false;
        },
        // 下载文件
        downLoad(item) {
            this.commonJs.fileDownLoad(item);
        },
        // 删除文件
        delFile(item) {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .deleteFileById(item)
                .then(() => {
                    loading.close();
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取文件列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".popFileHistory"),
            });
            commonAPI
                .getFileList({
                    resourceId: this.resourceId,
                    resourceType: this.fileHistoryType.resourceType,
                    // resourceId: "1",
                    // resourceType: "RESERVE_PRO_PRE_PLAN",
                })
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    data.forEach((item) => {
                        item.timestamp = item.updateTime;
                    });
                    this.activities = data;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.warp {
    padding-left: 140px;
}
.el-timeline-item {
    min-height: 100px;
}
.el-timeline-item .left {
    position: absolute;
    left: -150px;
    top: -12px;
    text-align: right;
    color: #595959;
}
.fileList .fileItem {
    background: url("../assets/imgs/icon_fileico.png") no-repeat 15px center;
    margin-bottom: 15px;
    padding: 10px 15px 10px 35px;
    cursor: pointer;
    transition: all 0.5s;
}
.fileList .fileItem:hover {
    background-color: #f3f6f9;
    transition: all 0.5s;
}
.fileList .fileItem .fileName {
    max-width: 350px;
}
.fileList .fileItem:hover .fileName {
    transition: all 0.5s;
    color: #1b91ff;
}
.fileList .fileItem .controlBtn span:hover {
    transition: all 0.5s;
    color: #1b91ff;
}
.fileList span {
    margin-right: 5px;
}
</style>