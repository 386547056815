// 文件管理
<template>

    <div class="popFilesHistory">
        <el-drawer title="文件管理"
                   :visible.sync="dialogVisible"
                   :append-to-body="true"
                   direction="rtl"
                   :before-close="close">
            <el-collapse v-model="activeNames"
                         accordion
                         @change="handleChange">
                <el-collapse-item v-for="item in fileManage"
                                  :key="item.value"
                                  :name="item.value">
                    <!-- projectAcceptance 竣工验收结算资料 projectAudit 审计资料 以上两个模块需要和合同一起显示 -->
                    <!-- 不需要合同的文件模块 -->
                    <template v-if="item.value !== 'projectAcceptance' && item.value !== 'projectAudit'">
                        <template slot="title">
                            <span class="redcircle"
                                  v-if="item.defaultFlag"></span>
                            <span class="bluecircle"
                                  v-else></span>
                            <span class="fileTypeName mr10">{{item.name}}</span>
                            <el-upload class="upload-demo"
                                       name="file"
                                       :headers="configHeader"
                                       :action="action"
                                       :on-success="uploadSuccess"
                                       :on-error="uploadError"
                                       multiple
                                       :data="uploadData"
                                       :show-file-list="false">
                                <template v-if="!disableUpload">
                                    <span class="uploadFile"
                                          v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_上传文件')"
                                          @click="handleUploadData(item)"></span>
                                </template>
                            </el-upload>
                            <span class="time"
                                  v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_历史记录')"
                                  @click.stop="openpopFileHistory(item)"></span>
                            <!-- 是否有文件标记 -->
                            <span class="isUpload el-icon-success green"
                                  style="font-size: 16px"
                                  v-if="commonJs.hadFile(item.name,fileCount)"></span>
                        </template>
                        <ul class="fileList"
                            v-if="fileList.length">
                            <li class="fileItem ofh"
                                v-for="(item,idx) in fileList"
                                :key="idx">
                                <div class="fll">
                                    <span class="fileico"></span>
                                    <span class="fileName ofh">
                                        <el-tooltip class="item"
                                                    effect="dark"
                                                    :content="item.name">
                                            <span class="ellipsis"
                                                  style="width: 300px">{{item.name || "暂无"}}</span>
                                        </el-tooltip>
                                    </span>
                                </div>
                                <div class="flr">
                                    <template v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')">
                                        <i class="el-icon-view cursor"
                                           style="margin-right:8px"
                                           v-if="commonJs.filterIsImg(item.name) || commonJs.filterIsPdf(item.name)"
                                           @click="seeView(item)"></i>
                                    </template>
                                    <span class="downFile"
                                          v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')"
                                          @click="down(item)"></span>
                                </div>
                            </li>
                        </ul>
                        <div class="emptyBg"
                             v-else>
                        </div>
                    </template>
                    <!-- 需要合同的模块 -->
                    <template v-else>
                        <template slot="title">
                            <span class="redcircle"
                                  v-if="item.defaultFlag"></span>
                            <span class="bluecircle"
                                  v-else></span>
                            <span class="fileTypeName mr15">{{item.name}}</span>
                            <!-- 是否有文件标记 -->
                            <span class="isUpload el-icon-success green"
                                  style="font-size: 16px"
                                  v-if="commonJs.hadFile(item.name,fileCount)"></span>
                        </template>
                        <template v-if="contractList && contractList.length > 0">
                            <div class="fileListWithContract"
                                 v-for="(it,i) in contractList"
                                 :key="i">
                                <div class="contractFileBox ofh">
                                    <!-- 合同名字 -->
                                    <span class="contractName fll">{{it.name}}</span>
                                    <el-upload class="upload-demo fll"
                                               name="file"
                                               :headers="configHeader"
                                               :action="action"
                                               :on-success="uploadSuccess"
                                               :on-error="uploadError"
                                               multiple
                                               :data="uploadData"
                                               :show-file-list="false">
                                        <template v-if="!disableUpload">
                                            <span class="uploadFile"
                                                  v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_上传文件')"
                                                  @click="handleUploadData(it, it.projectContractId, item.value)"></span>
                                        </template>
                                    </el-upload>
                                    <span class="time fll"
                                          v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_历史记录')"
                                          style="margin-top: 5px;"
                                          @click.stop="openpopFileHistory(it, it.projectContractId, item.value)"></span>
                                </div>
                                <ul class="fileList"
                                    v-if="contractFile[it.projectContractId] && contractFile[it.projectContractId].length">
                                    <li class="fileItem ofh"
                                        v-for="(t,s) in contractFile[it.projectContractId]"
                                        :key="s">
                                        <div class="fll">
                                            <span class="fileico"></span>
                                            <span class="fileName ofh">
                                                <el-tooltip class="item"
                                                            effect="dark"
                                                            :content="t.name">
                                                    <span class="ellipsis"
                                                          style="width: 300px">{{t.name || "暂无"}}</span>
                                                </el-tooltip>
                                            </span>
                                        </div>
                                        <div class="flr">
                                            <template v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')">
                                                <i class="el-icon-view cursor"
                                                   style="margin-right:8px"
                                                   v-if="commonJs.filterIsImg(t.name) || commonJs.filterIsPdf(t.name)"
                                                   @click="seeView(t)"></i>
                                            </template>
                                            <span class="downFile"
                                                  v-if="commonJs.isShowBtn(detailRole, 'Btn_文件管理_下载文件')"
                                                  @click="down(t)"></span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="emptyBg"
                                     v-else></div>
                            </div>
                        </template>
                        <div class="emptyBg"
                             v-else></div>
                    </template>
                </el-collapse-item>
                <!-- 施工日志 -->
                <el-collapse-item name="施工日志"
                                  v-if="type === '实施管理'">
                    <template slot="title">
                        <span class="bluecircle"></span>
                        <span class="fileTypeName mr15">施工日志</span>
                    </template>
                    <ul class="logList"
                        v-if="logList.length>0">
                        <li v-for="(item,idx) in logList"
                            :key="idx"
                            @click="openLogDetails(item)">{{item.constructionTime}}
                            <!-- <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           @confirm="delSetupLog(item.id)"
                                           icon="el-icon-info"
                                           icon-color="red"
                                           title="真的要删除吗？">
                                <div class="close el-icon-error"
                                     slot="reference"
                                     @click.stop></div>
                            </el-popconfirm> -->
                        </li>
                    </ul>
                    <div class="emptyBg"
                         v-else></div>
                </el-collapse-item>

            </el-collapse>
        </el-drawer>

        <!-- 文件历史 -->
        <pop-file-history v-if="popFileHistoryVisible"
                          :resourceId="fileResourceId"
                          :fileHistoryType="fileHistoryType"
                          :detailRole="detailRole"
                          @close="closepopFileHistory"></pop-file-history>
        <!-- 新建施工日志 - 弹窗 -->
        <pop-implementation-log v-if="popImplementationLogVisible"
                                :isEditLogStatus="isEditLogStatus"
                                :detailsId="resourceId"
                                :editLoginfo="editLoginfo"
                                @close="closepopImplementationLog"></pop-implementation-log>

        <!-- 图片预览 -->
        <elImageViewer v-if="showViewer"
                       :onClose="showViewerClose"
                       :url-list="[guidePic]"></elImageViewer>

        <!-- pdf预览 -->
        <viewPdf v-if="showPdfView"
                 :pdfUrl="pdfUrl"
                 :title="filename"
                 @close="closeDialog"></viewPdf>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
import popFileHistory from "@/components/popFileHistory.vue";
import popImplementationLog from "../../projectManage/components/popImplementationLog.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer"; //  图片预览组件
import viewPdf from "@/components/viewPdf/viewPdf"; //  PDF预览组件
export default {
    name: "popFilesHistory",

    props: {
        fileManage: {
            type: Array,
            default() {
                return [];
            },
        },
        resourceId: {
            // 资源Id
            type: String,
            default: "",
        },
        contractList: {
            // 合同列表
            type: Array,
            default() {
                return [];
            },
        },
        detailRole: {
            type: Object,
        },
        type: {
            type: String,
            default: "实施管理",
        },
        moduleType: {
            type: String,
            default: "PROJECT",
        },
        // 是否可以上传
        disableUpload: {
            type: Boolean,
        },
    },

    components: {
        "pop-file-history": popFileHistory,
        "pop-implementation-log": popImplementationLog,
        elImageViewer: ElImageViewer,
        viewPdf: viewPdf,
    },

    data() {
        return {
            dialogVisible: true,
            activeNames: "",
            fileList: [], // 查询到的文件列表
            popFileHistoryVisible: false, // 文件历史记录弹窗显示
            fileHistoryType: null, // 文件历史所需type
            popImplementationLogVisible: false, // 实施日志弹窗显示
            // 特殊的文件 需要和合同关联
            contractFiletype: ["projectAcceptance", "projectAudit"],

            //上传数据
            uploadData: {},

            // 施工日志
            logList: [],
            // 合同文件列表
            contractFile: {},
            fileResourceId: "", // 文件历史所需id
            fileCount: null, // 文件数量

            guidePic: null, // 预览图片的地址
            pdfUrl: null, // 预览pdf的地址
            showViewer: false, //大图查看
            showPdfView: false, //pdf查看
            filename: "", //当前查看的文件名称
            fileBoxHeigh: "300px",
            token: this.commonJs.getCookie("ERPToken"),
        };
    },

    created() {},

    mounted() {
        // 获取文件数量
        this.getFileCount();
    },

    methods: {
        //查看附件
        seeView(data) {
            if (
                !this.commonJs.filterIsImg(data.name) &&
                !this.commonJs.filterIsPdf(data.name)
            ) {
                return this.$message({
                    message: "当前只支持预览pdf和图片",
                    type: "warning",
                });
            }
            this.filename = data.name;
            /* 为图片时  大图查看 */
            if (this.commonJs.filterIsImg(data.name)) {
                this.showViewer = true;
                this.guidePic = `${window.configPath.baseUrl}/filem/download?id=${data.id}&access-token=${this.token}`;
            } else if (this.commonJs.filterIsPdf(data.name)) {
                /* 为pdf时  大图查看 */
                this.showPdfView = true;
                this.pdfUrl = `${window.configPath.baseUrl}/filem/view?id=${data.id}`;
            }
        },
        //大图查看关闭
        showViewerClose() {
            this.showViewer = false;
        },
        /* 关闭文件预览弹框 */
        closeDialog() {
            this.showPdfView = false;
        },
        // 打开施工日志详情
        openLogDetails(item) {
            this.isEditLogStatus = "详情";
            this.editLoginfo = item;
            this.openpopImplementationLog();
        },
        // 实施日志 打开弹框
        openpopImplementationLog() {
            this.popImplementationLogVisible = true;
        },
        // 实施日志 关闭弹框
        closepopImplementationLog() {
            this.popImplementationLogVisible = false;
            this.$emit("update");
        },
        // 处理上传时所需数据
        handleUploadData(item, id, type) {
            this.uploadData = {
                resourceId: id || this.resourceId,
                resourceType: type || item.value,
            };
        },
        // 文件历史记录 打开弹框
        openpopFileHistory(item, id, type) {
            this.fileResourceId = id || this.resourceId;
            this.fileHistoryType = {
                resourceType: type || item.value,
            };
            this.popFileHistoryVisible = true;
        },
        // 文件历史记录 关闭弹框
        closepopFileHistory() {
            this.fileResourceId = "";
            this.fileHistoryType = null;
            this.popFileHistoryVisible = false;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 手风琴折起
        handleChange(val) {
            if (
                val !== "" &&
                val !== "施工日志" &&
                this.contractFiletype.indexOf(val) === -1
            ) {
                let data = {
                    resourceId: this.resourceId,
                    resourceType: val,
                };
                this.getFile(data);
            } else if (val === "施工日志") {
                // 查询施工日志
                this.getLog();
            } else if (this.contractFiletype.indexOf(val) !== -1) {
                // 需要查询合同对应的文件列表
                // 查询对应合同的文件
                this.contractList.forEach((item) => {
                    let data = {
                        resourceId: item.projectContractId,
                        resourceType: val,
                    };
                    this.getFile(data, `${item.projectContractId}`);
                });
            } else {
                // 清空数据
                this.logList = []; // 施工日志
                this.contractFile = {}; // 合同文件列表
                this.fileList = []; // 查询到的文件列表
            }
        },
        // 查询对应合同的文件
        getFile(data, listName) {
            // 获取文件
            let loading = Loading.service({
                target: document.querySelector(".el-collapse"),
            });
            commonAPI
                .getFileList(
                    Object.assign({}, data, {
                        latestFlag: true, // 只看最新
                    })
                )
                .then((res) => {
                    loading.close();
                    if (listName) {
                        this.$set(this.contractFile, listName, res.content);
                    } else {
                        this.fileList = res.content;
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取文件数量
        getFileCount() {
            let loading = Loading.service({
                target: document.querySelector(".popFilesHistory"),
            });
            commonAPI
                .getFileCount(this.moduleType, this.resourceId)
                .then((res) => {
                    loading.close();
                    this.fileCount = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取施工日志
        getLog() {
            let loading = Loading.service({
                target: document.querySelector(".logList"),
            });
            commonAPI
                .getTaskLog({ taskId: this.resourceId })
                .then((res) => {
                    loading.close();
                    this.logList = res.content.records;
                })
                .catch(() => {
                    loading.close();
                });
        },
        //上传成功
        uploadSuccess() {
            this.handleChange(this.activeNames);
            this.getFileCount();
            this.$emit("update");
        },
        //上传失败
        uploadError() {},
        //下载附件
        down(data) {
            this.commonJs.fileDownLoad(data);
        },
    },

    computed: {
        // 上传文件所需
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },
        //上传地址
        action() {
            return `${window.configPath.baseUrl}/filem/upload`;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.popFilesHistory ::v-deep .el-collapse-item__content,
.popFilesHistory ::v-deep .el-collapse-item__header {
    padding: 0 20px;
}
.uploadFile {
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_uploadFile.png") no-repeat center
        center;
    background-size: 16px 16px;
    margin-right: 16px;
    margin-left: 16px;
}
.openFile {
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_openFile.png") no-repeat center
        center;
    background-size: 16px 16px;
    margin-right: 6px;
}
.fileico {
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_fileico.png") no-repeat center
        center;
    background-size: 16px 16px;
    margin-right: 6px;
}
.time {
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_time.png") no-repeat center
        center;
    background-size: 16px 16px;
    margin-right: 16px;
}
.downFile {
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../assets/imgs/icon_downFile.png") no-repeat center
        center;
    background-size: 16px 16px;
    margin-right: 6px;
}
.fileList {
    padding: 0;
    margin: 0;
}
.fileList .fileItem {
    width: 80%;
    line-height: 25px;
    background: #f1f1f1;
    margin-bottom: 10px;
    padding: 6px;
    border-radius: 5px;
}
.fileList .fileItem .fileName {
    vertical-align: middle;
    display: inline-block;
    color: #626262;
}
.fileList .fileItem .time,
.fileList .fileItem .downFile,
.fileList .fileItem .el-icon-view {
    display: none;
    transition: all 0.5s;
}
.fileList .fileItem:hover .time,
.fileList .fileItem:hover .downFile,
.fileList .fileItem:hover .el-icon-view {
    display: inline-block;
    transition: all 0.5s;
}

// 施工日志
.logList {
    padding: 0;
    margin: 0;
}
.logList li {
    background: coral;
    margin-bottom: 15px;
    padding: 0 15px 0 45px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    background-color: rgba(243, 246, 249, 100);
    background: url("../../../assets/imgs/icon_fileico.png") no-repeat 15px
        center;
    background-size: 15px;
    color: #595959;
    font-size: 14px;
    border: 1px solid rgba(217, 217, 217, 100);
    position: relative;
}
.logList,
.addBar {
    width: 400px;
}
.close {
    font-size: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    color: #1990ff;
    cursor: pointer;
    transition: all 0.5s;
}
.close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
.contractFileBox {
    background: #e1e1e1;
    padding: 5px 15px;
    width: 80%;
}
</style>