// 实施日志
<template>

    <div class="popImplementationLog">
        <el-dialog :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <div slot="title"
                 class="logTitle">
                <!-- 新建日志 -->
                <div class="newTitle"
                     v-if="isEditLogStatus === '新建'">
                    <el-date-picker v-model="form.constructionTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日志日期">
                    </el-date-picker>
                </div>
                <!-- 编辑日志 -->
                <div class="detailsTitle"
                     v-else-if="isEditLogStatus === '编辑'">
                    <span v-if="!isEditTitle">{{form.constructionTime}}
                        <i class="el-icon-edit cup"
                           @click="isEditTitle = true"
                           v-if="isEditLogStatus"></i>
                    </span>
                    <el-input v-if="isEditTitle"
                              v-model="form.constructionTime"
                              placeholder="请输入日志标题"></el-input>
                </div>
                <!-- 查看日志 -->
                <span v-else>{{form.constructionTime}}</span>
            </div>
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     :inline="false">
                <el-form-item label="工作事项"
                              prop="workDetail">
                    <el-input v-model="form.workDetail"
                              type="textarea"
                              :rows="4"
                              placeholder="请输入工作事项"></el-input>
                </el-form-item>
            </el-form>

            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     :inline="false">
                <el-form-item label="风险点及解决方案"
                              prop="problemResolve">
                    <el-input v-model="form.problemResolve"
                              type="textarea"
                              :rows="4"
                              placeholder="请输入风险点及解决方案"></el-input>
                </el-form-item>
            </el-form>

            <div class="title">现场照片</div>
            <!-- 相册组件 -->
            <photo-album :photos="photos"
                         :isNeedEmptyBg="false">
                <div slot="upload"
                     v-if="isEditLogStatus === '新建'">
                    <el-upload class="avatar-uploader"
                               v-if="commonJs.isShowBtn(detailRole,'Btn_新建施工日志')"
                               :headers="configHeader"
                               :action="action"
                               :show-file-list="false"
                               :data="uploadData"
                               :on-success="uploadSuccess"
                               :on-error="uploadError"
                               :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl"
                             :src="imageUrl"
                             class="avatar">
                        <i v-else
                           class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div slot="upload"
                     v-else>
                    <el-upload class="avatar-uploader"
                               v-if="commonJs.isShowBtn(detailRole,'Btn_编辑施工日志')"
                               :headers="configHeader"
                               :action="action"
                               :show-file-list="false"
                               :data="uploadData"
                               :on-success="uploadSuccess"
                               :on-error="uploadError"
                               :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl"
                             :src="imageUrl"
                             class="avatar">
                        <i v-else
                           class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </photo-album>

            <template v-if="isEditLogStatus === '新建'">
                <div class="footerBtn"
                     v-if="commonJs.isShowBtn(detailRole,'Btn_新建施工日志')">
                    <el-button size="mini"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               size="mini"
                               @click="save">确定</el-button>
                </div>
            </template>
            <template v-else>
                <div class="footerBtn"
                     v-if="commonJs.isShowBtn(detailRole,'Btn_编辑施工日志')">
                    <el-button size="mini"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               size="mini"
                               @click="save">确定</el-button>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import PhotoAlbum from "@/components/PhotoAlbumNew";
import API from "@/api/implementationManage.js";
import commonAPI from "@/api/commonAPI.js";
import { Loading } from "element-ui";
export default {
    name: "popImplementationLog",

    props: {
        detailRole: {
            type: Object,
        },
        isEditLogStatus: {
            type: String,
            default: "新建",
        },
        detailsId: {
            // 项目Id
            type: String,
            default: "",
        },
        editLoginfo: {
            // 编辑的信息
            type: Object,
            default() {
                return {
                    id: "",
                };
            },
        },
    },

    components: {
        "photo-album": PhotoAlbum,
    },

    data() {
        return {
            dialogVisible: true,
            isEditTitle: false, // 编辑标题状态
            form: {
                id: "", // 主键
                taskId: "", // 项目id
                workDetail: "", // 工作事项
                constructionTime: "", // 日志标题
                problemResolve: "", // 风险点及解决方案
            },
            photos: [], // 相册
            formRules: {},
            imageUrl: "", // 上传图片
        };
    },

    created() {},

    mounted() {
        if (!this.editLoginfo.id) {
            // 新增
            // 获取唯一主键
            this.getUniqueId();
            this.form.constructionTime = this.commonJs.nowTime();
        } else {
            // 编辑
            this.form = this.editLoginfo;
            // 获取施工日志照片
            this.getLogImgs();
        }
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 上传图片
        // 上传前检测
        beforeAvatarUpload(file) {
            const isJPG = file.type.indexOf("image") !== -1;

            if (!isJPG) {
                this.$message.error("该处只能上传图片！");
            }
            return isJPG;
        },
        //上传成功
        uploadSuccess() {
            this.getLogImgs();
        },
        //上传失败
        uploadError() {},
        // 获取施工日志照片
        getLogImgs() {
            let loading = Loading.service({
                target: document.querySelector(".photoLis"),
            });
            let data = {
                resourceId: this.form.id,
                resourceType: "sitePicture",
            };
            commonAPI
                .getFileList(data)
                .then((res) => {
                    let arr = [];
                    res.content.forEach((item) => {
                        arr.push(item.id);
                    });
                    this.photos = arr;
                    loading.close();
                })
                .catch(() => {
                    this.$message({
                        message: "新建日志出了一点问题，请稍后再试吧!",
                        type: "error",
                    });
                    this.close();
                    loading.close();
                });
        },
        // 获取唯一主键
        getUniqueId() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getUniqueId()
                .then((res) => {
                    this.form.id = res.content;
                    loading.close();
                })
                .catch(() => {
                    this.$message({
                        message: "新建日志出了一点问题，请稍后再试吧!",
                        type: "error",
                    });
                    this.close();
                    loading.close();
                });
        },
        // 保存日志 || 编辑日志
        save() {
            if (this.form.constructionTime) {
                let loading = Loading.service({
                    target: document.querySelector(".el-dialog"),
                });
                let data = Object.assign({}, this.form, {
                    taskId: this.detailsId,
                });
                API.saveSetupLog(data).then(() => {
                    loading.close();
                    this.$message({
                        message: "保存成功！",
                        type: "success",
                    });
                    this.close();
                });
            } else {
                this.$message({ message: "请选择日日期", type: "error" });
            }
        },
    },

    computed: {
        // 上传文件所需
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },
        //上传地址
        action() {
            return `${window.configPath.baseUrl}/filem/upload`;
        },
        //上传数据
        uploadData() {
            return {
                resourceId: this.form.id,
                resourceType: "sitePicture",
            };
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.popImplementationLog ::v-deep .el-form-item__label {
    width: 100%;
    height: 33px;
    line-height: 33px;
    margin-bottom: 15px;
    background: #f3f3f3;
    border-radius: 2px;
    padding: 0 18px;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    text-align: left;
}
.info {
    background: #eee;
    padding: 10px 15px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    margin-bottom: 20px;
    font-size: 14px;
    word-break: keep-all;
    white-space: pre-line;
}
.footerBtn {
    padding-top: 20px;
    text-align: right;
}

// 日志标题
.popImplementationLog ::v-deep .el-dialog__header {
    padding: 10px 15px;
    line-height: 35px;
}
.logTitle {
    color: #ffffff;
}
.logTitle .el-input {
    margin-bottom: 15px;
    width: 220px;
}
</style>