// 部署实施 - 设备需求清单
<template>

    <div class="devicePlanListTable">
        <el-card>
            <div slot="header">设备需求清单 <span class="status">{{setupDetailsInfo.equipmentStatus.text}}</span></div>
            <div class="miniTable">
                <el-table :data="setupDetailsInfo.modelCountVos"
                          border
                          stripe>
                    <el-table-column label="序号"
                                     type="index"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     align="center"
                                     :min-width="col.width">
                    </el-table-column>
                </el-table>
                <div class="totalBar">
                    <i class="blue el-icon-warning mr10"></i>
                    <span v-if="setupDetailsInfo.modelCountVos">共<i>{{setupDetailsInfo.modelCountVos.length}}</i>类设备</span>
                    <span>采购总计：<i class="blue">{{countsInfo.buyCount}}</i>件</span>
                    <span>出库总计：<i class="blue">{{countsInfo.outCount}}</i>件</span>
                    <span>验收总计：<i class="blue">{{countsInfo.hadCount}}</i>件</span>
                    <!-- <el-button type="primary"
                               size="mini"
                               class="flr"
                               @click="openpopIsCheckedDevice">设备信息</el-button> -->

                </div>
            </div>
        </el-card>

        <!-- 已验收设备清单 -->
        <pop-is-checked-device v-if="popIsCheckedDeviceVisible"
                               @close="closepopIsCheckedDevice"></pop-is-checked-device>
    </div>

</template>

<script>
import PopIsCheckedDevice from "./popIsCheckedDevice";
export default {
    name: "devicePlanListTable",

    props: {
        setupDetailsInfo: {
            type: Object,
            default() {
                return {
                    checkedDeviceList: [], // 已验收设备清单
                    modelCountVos: [], // 设备需求清单
                    logList: [], // 施工日志
                    outFlowList: [], // 出库记录
                };
            },
        },
    },

    components: {
        PopIsCheckedDevice,
    },

    data() {
        return {
            tableData: {
                columns: [
                    { id: "empType", label: "设备类型", width: "100" },
                    { id: "empTypeModel", label: "设备型号", width: "100" },
                    { id: "number", label: "需求数量", width: "100" },
                    { id: "outNumber", label: "出库数量", width: "100" },
                    { id: "checkedNum", label: "验收数量", width: "100" },
                    { id: "installNum", label: "安装数量", width: "100" },
                ],
            },
            popIsCheckedDeviceVisible: false, // 已验收设备清单弹窗显示
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 已验收设备清单 打开弹框
        openpopIsCheckedDevice() {
            this.popIsCheckedDeviceVisible = true;
        },
        // 已验收设备清单 关闭弹框
        closepopIsCheckedDevice() {
            this.popIsCheckedDeviceVisible = false;
        },
    },

    computed: {
        // 统计数量
        countsInfo() {
            let obj = {
                buyCount: 0,
                outCount: 0,
                hadCount: 0,
            };
            if (this.setupDetailsInfo.modelCountVos) {
                this.setupDetailsInfo.modelCountVos.forEach((item) => {
                    // 采购总计
                    obj.buyCount += item.number;
                    // 出库总计
                    obj.outCount += item.outNumber;
                    // 验收总计
                    obj.hadCount += item.checkedNum;
                });
            }
            return obj;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.addBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #d8f4ff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #00a2e9;
    text-align: center;
    cursor: pointer;
}
// 合计栏
.totalBar {
    margin-top: 15px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #9093b3;
    padding-left: 15px;
}
.totalBar span {
    margin-right: 15px;
}
.el-card__header .status {
    display: inline-block;
    border: 1px solid #40aaff;
    color: #40aaff;
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 5px;
    margin-left: 10px;
}
</style>
