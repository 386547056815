// 施工日志
<template>

    <div class="comConstructionLog">
        <el-card>
            <div slot="header">
                <span>施工日志</span>
            </div>
            <ul class="logList">
                <li v-for="(item,idx) in setupDetailsInfo.logList"
                    :key="idx"
                    @click="openLogDetails(item)">{{item.constructionTime}}
                    <template v-if="commonJs.isShowBtn(detailRole,'Btn_删除施工日志')">
                        <el-popconfirm confirm-button-text="删除"
                                       cancel-button-text="取消"
                                       @confirm="delSetupLog(item.id)"
                                       icon="el-icon-info"
                                       icon-color="red"
                                       title="真的要删除吗？">
                            <div class="close el-icon-error"
                                 slot="reference"
                                 @click.stop></div>
                        </el-popconfirm>
                    </template>
                </li>
            </ul>
            <div class="addBar">
                <template v-if="commonJs.isShowBtn(detailRole,'Btn_新建施工日志')">
                    <div class="addBar_btn"
                         @click="addLog">+ 点击新建施工日志</div>
                </template>
            </div>
        </el-card>

        <!-- 新建施工日志 - 弹窗 -->
        <pop-implementation-log v-if="popImplementationLogVisible"
                                :isEditLogStatus="isEditLogStatus"
                                :detailsId="detailsId"
                                :editLoginfo="editLoginfo"
                                :detailRole="detailRole"
                                @close="closepopImplementationLog"></pop-implementation-log>

    </div>

</template>

<script>
import popImplementationLog from "../../projectManage/components/popImplementationLog.vue";
import API from "@/api/implementationManage.js";
import { Loading } from "element-ui";
export default {
    name: "comConstructionLog",

    props: {
        detailRole: {
            type: Object,
        },
        detailsId: {
            // 项目Id
            type: String,
            default: "",
        },
        setupDetailsInfo: {
            type: Object,
            default() {
                return {
                    checkedDeviceList: [], // 已验收设备清单
                    modelCountVos: [], // 计划设备清单
                    logList: [], // 施工日志
                    outFlowList: [], // 出库记录
                };
            },
        },
    },

    components: {
        "pop-implementation-log": popImplementationLog,
    },

    data() {
        return {
            popImplementationLogVisible: false, // 实施日志弹窗显示
            isEditLogStatus: "新建",
            editLoginfo: {}, // 编辑的项目
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 实施日志 打开弹框
        openpopImplementationLog() {
            this.popImplementationLogVisible = true;
        },
        // 实施日志 关闭弹框
        closepopImplementationLog() {
            this.popImplementationLogVisible = false;
            this.$emit("update");
        },
        // 打开施工日志详情
        openLogDetails(item) {
            this.isEditLogStatus = "详情";
            this.editLoginfo = item;
            this.openpopImplementationLog();
        },
        // 新建施工日志
        addLog() {
            this.isEditLogStatus = "新建";
            this.editLoginfo = {};
            this.openpopImplementationLog();
        },
        // 施工日志 / 删除日志
        delSetupLog(id) {
            let loading = Loading.service({
                target: document.querySelector(".comConstructionLog"),
            });
            API.delSetupLog(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "删除成功！", type: "success" });
                    this.$emit("update");
                })
                .catch(() => {
                    this.$message({
                        message: "删除失败，请稍后再试！",
                        type: "error",
                    });
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.logList {
    padding: 0;
    margin: 0;
}
.logList li {
    background: coral;
    margin-bottom: 15px;
    padding: 0 15px 0 45px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    background-color: rgba(243, 246, 249, 100);
    background: url("../../../assets/imgs/icon_fileico.png") no-repeat 15px
        center;
    background-size: 15px;
    color: #595959;
    font-size: 14px;
    border: 1px solid rgba(217, 217, 217, 100);
    position: relative;
}
.logList,
.addBar {
    width: 400px;
}
.close {
    font-size: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    color: #1990ff;
    cursor: pointer;
    transition: all 0.5s;
}
.close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
</style>
