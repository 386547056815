// 计划信息
<template>

    <div class="itemInfo">

        <el-card>
            <div slot="header">
                <span>计划信息</span>
            </div>
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="150px"
                     label-position="top"
                     :inline="false">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item prop="customerCompanyName">
                            <span slot="label"><i class="red mr5">*</i>客户</span>
                            <!-- @click="toPersonDetails" -->
                            <div class="itemCard"
                                 v-if="detailsInfo.customerCompanyName">
                                <!-- <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="cleanPerson"
                                               title="真的要删除吗？">
                                    <span class="close el-icon-error"
                                          slot="reference"></span>
                                </el-popconfirm> -->
                                {{detailsInfo.customerCompanyName}}
                            </div>
                            <el-link type="primary"
                                     v-else
                                     @click="openselectPerson">点击选择客户</el-link>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户联系人"
                                      prop="chargeLinkName">
                            <el-input v-model="detailsInfo.chargeLinkName"
                                      placeholder="请输入客户联系人"
                                      disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系方式"
                                      prop="chargeLinkPhone">
                            <el-input v-model="detailsInfo.chargeLinkPhone"
                                      placeholder="请输入联系方式"
                                      disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="实施地点"
                                      prop="address">
                            <el-input v-model="detailsInfo.address"
                                      placeholder="请输入实施地点"
                                      disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="实施负责人"
                                      prop="operatorName">
                            <el-input v-model="detailsInfo.operatorName"
                                      placeholder="请输入实施负责人"
                                      disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="项目产品"
                                      prop="systemId">
                            <el-select v-model="detailsInfo.systemId"
                                       class="fll"
                                       placeholder="请选择项目产品"
                                       disabled
                                       clearable>
                                <el-option v-for="item in systemListOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- 跳转关闭 -->
                            <!-- <el-link type="primary"
                                     v-if="detailsInfo.systemId"
                                     class="fll"
                                     :underline="false"
                                     @click="toItemDetails"
                                     target="_blank">查看</el-link> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="关联项目"
                                      prop="projectId">
                            <el-select v-model="detailsInfo.projectId"
                                       placeholder="请选择关联项目"
                                       disabled
                                       clearable>
                                <el-option v-for="item in itemSelectOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- 跳转关闭 -->
                            <!-- <el-link type="primary"
                                     v-if="detailsInfo.projectId"
                                     :href="`/#/projectManage/projectManageDetails?status=编辑&id=${detailsInfo.projectId}`"
                                     target="_blank">查看</el-link> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划开始时间"
                                      prop="startTime">
                            <el-date-picker v-model="detailsInfo.startTime"
                                            type="date"
                                            disabled
                                            value-format="yyyy-MM-dd"
                                            placeholder="请选择计划开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划完成时间"
                                      prop="endTime">
                            <el-date-picker v-model="detailsInfo.endTime"
                                            type="date"
                                            disabled
                                            value-format="yyyy-MM-dd"
                                            placeholder="请选择计划完成时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="计划描述"
                                      prop="description">
                            <el-input type="textarea"
                                      :rows="5"
                                      placeholder="请输入计划描述或计划内容"
                                      v-model="detailsInfo.description">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <!-- 新增系统弹窗 -->
        <popAddSystem v-if="popAddSystemVisible"
                      @addSystem="addSystem"
                      @close="closepopAddSystem"></popAddSystem>
        <!-- 选择储备项目 -->
        <!-- <pop-select-reserve-project v-if="popSelectReserveProjectVisible"
                                    @close="closepopSelectReserveProject"></pop-select-reserve-project> -->
        <!-- 选择客户 -->
        <pop-select-person v-if="selectPersonVisible"
                           title="选择客户"
                           @close="closeselectPerson"></pop-select-person>

    </div>

</template>

<script>
import popSelectPerson from "../../safeguardManage/components/popSelectPerson.vue";
import commonAPI from "@/api/commonAPI.js";
import { Loading } from "element-ui";
// import popSelectReserveProject from "./popSelectReserveProject";

export default {
    name: "itemInfo",

    props: {
        step: {
            type: String,
            default: "初步机会",
        },
        // 详情
        detailsInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    components: {
        // "pop-select-reserve-project": popSelectReserveProject,
        "pop-select-person": popSelectPerson,
    },

    data() {
        return {
            popAddSystemVisible: false, // 新增系统弹窗显示
            popSelectReserveProjectVisible: false, // 选择储备项目弹窗显示
            selectPersonVisible: false, // 选择客户弹窗显示
            opts: [
                { label: "全部", value: "" },
                { label: "测试", value: "1" },
            ],
            itemSelectOpts: [{ label: "全部", value: "" }], // 软件信息下拉
            systemListOpts: [{ label: "全部", value: "" }], // 项目产品下拉
            // 计划信息
            itemForm: {
                test2: "", // 客户
                customerCompanyId: "", // 目标客户选中id
            },
            linkReserveItem: "", // 选择的关联的储备项目
            personInfo: "", // 选择的客户
            itemFormRules: {},
            customerOpts: [], // 目标客户 下拉
            tableData: {
                data: [],
                columns: [
                    { id: "系统名称", label: "系统名称", width: "100" },
                    { id: "所属部门", label: "所属部门", width: "50" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 使用软件 下拉
        this.getSystemList();
        // 项目名称 下拉列表
        this.getItemSelect();
    },

    methods: {
        // 查看项目产品详情
        toItemDetails() {
            let data = {
                id: this.detailsInfo.systemId,
                name: "甘肃省检修大屏",
            };
            let routeData = this.$router.resolve({
                path: "/baseInfoManage/systemDetail",
                query: { systemInfo: JSON.stringify(data) },
            });
            window.open(routeData.href, "_blank");
        },
        // 查看客户详情
        toPersonDetails() {
            let data = {
                value: this.detailsInfo.customerCompanyId,
                type: "CUSTOMER", // PROVIDER：供应商信息  CUSTOMER：客户信息
            };
            let routeData = this.$router.resolve({
                path: "/baseInfoManage/supplierDetail",
                query: { deviceTypeInfo: JSON.stringify(data) },
            });
            window.open(routeData.href, "_blank");
        },
        // 使用软件 下拉
        getSystemList() {
            commonAPI
                .getSystemList()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.systemListOpts = opt;
                })
                .catch(() => {
                    this.$message({
                        message: "获取使用软件失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 项目名称 下拉列表
        getItemSelect() {
            commonAPI
                .getItemSelect()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.itemSelectOpts = opt;
                })
                .catch(() => {
                    this.$message({
                        message: "获取项目名称失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 清空关联储备项目
        cleanItem() {
            this.itemForm.test1 = "";
            this.linkReserveItem = "";
        },
        // 清空选择的客户
        cleanPerson() {
            this.detailsInfo.customerCompanyName = "";
            this.personInfo = "";
        },
        // 选择选择客户
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择选择客户弹框
        closeselectPerson(personName, operatorId) {
            this.detailsInfo.customerCompanyName = personName;
            this.personInfo = { personName, operatorId };
            this.selectPersonVisible = false;
        },
        // 选择储备项目 打开弹框
        openpopSelectReserveProject() {
            this.popSelectReserveProjectVisible = true;
        },
        // 选择储备项目 关闭弹框
        closepopSelectReserveProject(row) {
            if (row) {
                console.log(row);
                this.itemForm.test1 = row.tabletest1;
                this.linkReserveItem = row;
            }
            this.popSelectReserveProjectVisible = false;
        },
        // 新增系统 保存
        addSystem(selectNode) {
            this.tableData.data.push(selectNode);
        },
        // 获取目标客户 树
        getCustomerTree() {
            commonAPI
                .getCustomerSelectTree({ type: "CUSTOMER" })
                .then((res) => {
                    this.customerOpts = res.content;
                })
                .catch(() => {
                    this.$message({
                        message: "获取目标客户失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 查看
        details() {},
        // 删除
        del() {},
    },

    computed: {},

    watch: {
        // 目标客户选择后 自动带入任务联系人
        "itemForm.customerCompanyId": function () {
            // 清空任务联系人
            this.form.taskLinkName = "";
            this.form.taskLinkPhone = "";
            // 重新获取任务联系人
            let loading = Loading.service({
                target: document.querySelector(".taskLink"),
            });
            commonAPI
                .getLinkPersonList({
                    companyId: this.form.customerCompanyId,
                })
                .then((res) => {
                    if (res.content && res.content.length > 0) {
                        let defaultPerson = res.content.filter(
                            (item) => item.isDefault
                        );
                        this.form.taskLinkName = defaultPerson[0].code;
                        this.form.taskLinkPhone = defaultPerson[0].label;
                    }
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.itemInfo .el-card__header .pic {
    float: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
}
.itemInfo .el-card__header .pic span {
    font-size: 26px;
}
// 选中的数据条带删除按钮
.itemCard {
    position: relative;
    padding: 0 20px 0 40px;
    border: 1px solid #d9d9d9;
    background: #f3f6f9 url("../../../assets/imgs/icon_itemIco.png") no-repeat
        16px 7px;
    font-size: 16px;
    color: #7a7a7b;
}
.itemCard .close {
    position: absolute;
    right: -7px;
    top: -7px;
    color: #8290a3;
    transition: all 0.5s;
    cursor: pointer;
}
.itemCard .close:hover {
    color: #ff0000;
    transition: all 0.5s;
}
</style>
