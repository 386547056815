// 实施管理详情
<template>

    <div class='implementationManageDetails'>
        <!-- 返回上级 -->
        <div style="padding:20px 20px 5px"
             class="ofh">
            <backUp class="fll"></backUp>
            <div class="flr topControlBar">
                <span class="el-icon-folder-opened"
                      v-if="commonJs.isShowBtnStrict(roleInfo,'Btn_文件管理_查看')"
                      @click="openpopFilesHistory"></span>
            </div>
        </div>

        <div class="contentWarp"
             :style="{height: Height+'px'}">

            <!-- 头部内容 -->
            <div class="header ofh">
                <p class="itemName fll">
                    {{detailsInfo.name}}
                </p>
                <div class="stepBox flr">
                    <el-steps :active="nowStep"
                              space="170px">
                        <el-step title="施工方案"
                                 :class="{
                                      'stepIng': stepIng == 0,
                                      'stepOver': finishStep.indexOf(0) != -1,
                                      'stepNotOver': finishStep.indexOf(0) == -1 && stepIng != 0
                                }"
                                 @click.native="nextStep(0)"></el-step>
                        <el-step title="开工准备"
                                 :class="{
                                      'stepIng': stepIng == 1,
                                      'stepOver': finishStep.indexOf(1) != -1,
                                      'stepNotOver': finishStep.indexOf(1) == -1 && stepIng != 1
                                }"
                                 @click.native="nextStep(1)"></el-step>
                        <el-step title="部署实施"
                                 :class="{
                                      'stepIng': stepIng == 2,
                                      'stepOver': finishStep.indexOf(2) != -1,
                                      'stepNotOver': finishStep.indexOf(2) == -1 && stepIng != 2
                                }"
                                 @click.native="nextStep(2)"></el-step>
                        <el-step title="培训验收"
                                 :class="{
                                      'stepIng': stepIng == 3,
                                      'stepOver': finishStep.indexOf(3) != -1,
                                      'stepNotOver': finishStep.indexOf(3) == -1 && stepIng != 3
                                }"
                                 @click.native="nextStep(3)"></el-step>
                        <el-step title="归档"
                                 :class="{
                                      'stepIng': stepIng == 4,
                                      'stepOver': finishStep.indexOf(4) != -1,
                                      'stepNotOver': finishStep.indexOf(4) == -1 && stepIng != 4
                                }"></el-step>
                    </el-steps>
                </div>
            </div>

            <!-- 施工方案 -->
            <div class="step1"
                 v-show="nowStep + 1 === 1">
                <el-row>
                    <el-col :span="24">
                        <!-- 计划信息 -->
                        <com-item-info :detailsInfo="detailsInfo"></com-item-info>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <!-- 文件 taskPlanStageFile -->
                        <com-file-list modelName="实施管理"
                                       :showDelBtn="!commonJs.isShowBtnStrict(roleInfo,'Btn_文件管理_删除文件')"
                                       :detailsInfo="detailsInfo"
                                       :resourceId="detailsId"
                                       :isDisableUpload="isDisableUpload"
                                       :detailRole="roleInfo"
                                       :fileTypeList="taskPlanStageFile"></com-file-list>
                    </el-col>
                </el-row>
            </div>

            <!-- 开工准备 -->
            <div class="step2"
                 v-show="nowStep + 1 === 2">

                <el-row>
                    <el-col :span="24">
                        <!-- 文件 taskReadyStageFile -->
                        <com-file-list modelName="实施管理"
                                       :showDelBtn="!commonJs.isShowBtnStrict(roleInfo,'Btn_文件管理_删除文件')"
                                       :detailsInfo="detailsInfo"
                                       :resourceId="detailsId"
                                       :isDisableUpload="isDisableUpload"
                                       :detailRole="roleInfo"
                                       :fileTypeList="taskReadyStageFile"></com-file-list>
                    </el-col>
                </el-row>

            </div>

            <!-- 部署实施 -->
            <div class="step3"
                 v-show="nowStep + 1 === 3">

                <el-row>
                    <el-col :span="24">
                        <!-- 施工日志 -->
                        <com-construction-log :setupDetailsInfo="setupDetailsInfo"
                                              :readOnly="readOnly"
                                              :detailsId="detailsId"
                                              :detailRole="roleInfo"
                                              @update="update('notJump')"></com-construction-log>
                    </el-col>
                </el-row>

                <!-- 已验收设备清单-列表 -->
                <!-- <el-row>
                    <el-col :span="24">
                        <com-device-list :setupDetailsInfo="setupDetailsInfo"></com-device-list>
                    </el-col>
                </el-row> -->

                <el-row>
                    <el-col :span="24">
                        <!-- 设备需求清单-列表 -->
                        <com-device-plan-list :setupDetailsInfo="setupDetailsInfo"></com-device-plan-list>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <!-- 出库记录-列表 -->
                        <com-out-history :setupDetailsInfo="setupDetailsInfo"></com-out-history>
                    </el-col>
                </el-row>

            </div>

            <!-- 培训验收 -->
            <div class="step4"
                 v-show="nowStep + 1 === 4">
                <el-row>
                    <el-col :span="24">
                        <!-- 文件 taskCheckStageFile -->
                        <com-file-list modelName="实施管理"
                                       :showDelBtn="!commonJs.isShowBtnStrict(roleInfo,'Btn_文件管理_删除文件')"
                                       :detailsInfo="detailsInfo"
                                       :resourceId="detailsId"
                                       :isDisableUpload="isDisableUpload"
                                       :detailRole="roleInfo"
                                       :fileTypeList="taskCheckStageFile"></com-file-list>
                    </el-col>
                </el-row>
            </div>

        </div>

        <!-- 是否只读 -->
        <template v-if="!readOnly">
            <!-- 如果是归档就没下方的操作 -->
            <el-footer class="footer"
                       v-if="detailsInfo.taskStage.text !== '归档'">
                <template v-if="commonJs.isShowBtnStrict(roleInfo,'Btn_废止')">
                    <div class="fll">
                        <el-popconfirm confirm-button-text="废止"
                                       cancel-button-text="取消"
                                       icon="el-icon-info"
                                       icon-color="red"
                                       @confirm="abolishTask"
                                       title="真的要废止吗？">
                            <el-button type="danger"
                                       slot="reference"
                                       size="mini">废止</el-button>
                        </el-popconfirm>
                    </div>
                </template>
                <div class="flr">
                    <template v-if="commonJs.isShowBtnStrict(roleInfo,'Btn_保存')">
                        <el-button type="success"
                                   size="mini"
                                   icon="el-icon-upload"
                                   v-show="nowStep == 0"
                                   @click="save()">保存</el-button>
                    </template>
                    <template v-if="commonJs.isShowBtnStrict(roleInfo,'Btn_跳过')">
                        <el-button size="mini"
                                   v-show="finishStep.indexOf(nowStep) == -1 && jumpStep.indexOf(nowStep) == -1 && nowStep != 3"
                                   @click="skipTask()">跳过</el-button>
                    </template>
                    <template v-if="commonJs.isShowBtnStrict(roleInfo,'Btn_完成本环节')">
                        <el-button type="primary"
                                   size="mini"
                                   v-show="finishStep.indexOf(nowStep) == -1"
                                   @click="overTask()">{{nowStep==3?'完成任务':'完成本环节'}}</el-button>
                    </template>

                </div>
            </el-footer>
        </template>

        <!-- 文件历史 -->
        <pop-files-history v-if="popFilesHistoryVisible"
                           :resourceId="detailsId"
                           moduleType="TASK"
                           :fileManage="fileManage"
                           :detailRole="roleInfo"
                           @close="closepopFilesHistory"></pop-files-history>

    </div>

</template>

<script>
import comOutHistory from "./components/comOutHistory.vue";
import comConstructionLog from "./components/comConstructionLog";
import comDevicePlanList from "./components/comDevicePlanList";
import comFileList from "./components/comFileList";
import comItemInfo from "./components/comItemInfo";
import popFilesHistory from "./components/popFilesHistory";
import backUp from "@/components/backUp";
import commonAPI from "@/api/commonAPI.js";
import API from "@/api/implementationManage.js";
import userRoleApi from "@/api/userRoleApi.js";
import { Loading } from "element-ui";

export default {
    name: "implementationManageDetails",

    props: [],

    components: {
        backUp,
        "pop-files-history": popFilesHistory,
        "com-item-info": comItemInfo,
        "com-file-list": comFileList,
        "com-device-plan-list": comDevicePlanList,
        "com-construction-log": comConstructionLog,
        "com-out-history": comOutHistory,
    },

    data() {
        return {
            roleInfo: null, // 数据权限
            readOnly: this.$route.query.readOnly || false, // 是否只读
            status: this.$route.query.status || null, // 当前页面是编辑还是查看
            detailsId: this.$route.query.id || null, // 编辑的id
            step: this.$route.query.step || null, // 环节名称
            popCreatItemVisible: false, // 生成项目弹窗显示
            popFilesHistoryVisible: false, // 文件历史弹窗显示
            Height: 0,
            nowStep: 0, //当前进度
            stepDict: [
                // 后端要的数据字典
                {
                    value: "PLAN",
                    label: "施工方案",
                },
                {
                    value: "READY",
                    label: "开工准备",
                },
                {
                    value: "DEPLOY",
                    label: "部署实施",
                },
                {
                    value: "CHECK",
                    label: "培训验收",
                },
            ],
            detailsInfo: {
                taskStage: {
                    text: "",
                },
            }, // step1 施工方案阶段详情
            setupDetailsInfo: {}, // step3 部署实施阶段详情
            nodeStatus: [], // 节点状态
            stepIng: "", // 当前进行中的节点index
            finishStep: [], // 已完成的节点index
            jumpStep: [], // 跳过的节点index
            fileManage: [], // 文件管理模块字典
            taskPlanStageFile: [], // 施工方案 - 文件
            taskReadyStageFile: [], // 开工准备 - 文件
            taskCheckStageFile: [], // 培训验收 - 文件
        };
    },

    created() {},

    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是184
        this.Height = document.documentElement.clientHeight - 184; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 184;
        };
        userRoleApi
            .getCurrentButtonByMenuId("1332132384109834241")
            .then((res) => {
                this.roleInfo = this.commonJs.tabIsAlive(
                    res.content,
                    "实施管理详情"
                );
            });
        // 查询详情
        this.update();
    },

    methods: {
        // 切换进度条
        nextStep(step) {
            if (step <= this.stepIng) {
                this.nowStep = step;
            }
        },
        // 查询详情
        // 查询详情已完成节点
        getNodeStatus() {
            let loading = Loading.service({
                target: document.querySelector(".header"),
            });
            API.getNodeStatus({
                taskId: this.detailsId,
            })
                .then((res) => {
                    loading.close();
                    let finishStepArr = [];
                    let jumpStepArr = [];
                    res.content.finishStages.forEach((item) => {
                        finishStepArr.push(item.sort - 1);
                    });
                    res.content.jumpStages.forEach((item) => {
                        jumpStepArr.push(item.sort - 1);
                    });
                    this.finishStep = finishStepArr;
                    this.jumpStep = jumpStepArr;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // step1 查询施工方案阶段详情
        getDetailsPlanInfo() {
            let loading = Loading.service({
                target: document.querySelector(".step1"),
            });
            API.getDetailsPlanInfo({
                id: this.detailsId,
            })
                .then((res) => {
                    loading.close();
                    this.detailsInfo = res.content;
                    this.stepIng = res.content.taskStage.sort - 1; // 序号
                    this.nowStep = this.stepIng === 4 ? 3 : this.stepIng;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // step3 查询部署实施阶段详情
        getDetailsSetup() {
            let loading = Loading.service({
                target: document.querySelector(".step3"),
            });
            API.getDetailsSetup({
                id: this.detailsId,
            })
                .then((res) => {
                    loading.close();
                    this.setupDetailsInfo = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 头部文件管理
        getModuleDict() {
            let loading = Loading.service({
                target: document.querySelector(".topControlBar"),
            });
            commonAPI
                .getModuleDict("TASK")
                .then((res) => {
                    loading.close();
                    this.fileManage = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 更新数据
        update(notJump) {
            if (!(notJump === "notJump")) {
                // 查询详情已完成节点
                this.getNodeStatus();
                // step1 查询施工方案阶段详情
                this.getDetailsPlanInfo();
            }
            // step3 查询部署实施阶段详情
            this.getDetailsSetup();
            // 头部文件管理
            this.getModuleDict();
            // 获取文件字典项列表
            this.getModuleCode("taskPlanStageFile"); // 施工方案
            this.getModuleCode("taskReadyStageFile"); // 开工准备
            this.getModuleCode("taskCheckStageFile"); // 培训验收
        },
        // 获取文件字典项列表
        getModuleCode(code) {
            let loading = Loading.service({
                target: document.querySelector(".comfileBox"),
            });
            commonAPI
                .getModuleCode(code)
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            resourceType: val["value"],
                            redOrBlue: val["defaultFlag"] ? "red" : "blue",
                            title: val["name"],
                        };
                    });
                    this[code] = opt;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存
        save() {
            let loading = Loading.service({
                target: document.querySelector(".implementationManageDetails"),
            });
            let data = {
                description: this.detailsInfo.description,
                id: this.detailsInfo.id,
            };
            API.updateTask(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "保存成功！", type: "success" });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 跳过施工环节
        skipTask() {
            let loading = Loading.service({
                target: document.querySelector(".implementationManageDetails"),
            });
            let data = {
                id: this.detailsId,
                stage: this.stepDict[this.nowStep].value,
            };
            API.skipTask(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "已跳过！", type: "success" });
                    this.nowStep += 1;
                    this.update();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 完成施工环节
        overTask() {
            if (this.nowStep === 3) {
                if (this.finishStep.length < 3) {
                    this.$message({
                        message: "请完成前置环节！",
                        type: "error",
                    });
                    return;
                }
            }
            let loading = Loading.service({
                target: document.querySelector(".implementationManageDetails"),
            });
            let data = {
                id: this.detailsId,
                stage: this.stepDict[this.nowStep].value,
            };
            API.overTask(data)
                .then(() => {
                    loading.close();
                    this.$message({
                        message: "已完成本环节！",
                        type: "success",
                    });
                    if (this.nowStep < 3) {
                        this.nowStep++;
                    }
                    this.update();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 废止项目
        abolishTask() {
            let loading = Loading.service({
                target: document.querySelector(".implementationManageDetails"),
            });
            let data = {
                id: this.detailsId,
            };
            commonAPI
                .abolishTask(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "已废止！", type: "success" });
                    this.$router.push({
                        path: "/implementationManage/index",
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 文件历史 打开弹框
        openpopFilesHistory() {
            this.popFilesHistoryVisible = true;
        },
        // 文件历史 关闭弹框
        closepopFilesHistory() {
            this.popFilesHistoryVisible = false;
        },
        // 生成项目 打开弹框
        openpopCreatItem() {
            this.popCreatItemVisible = true;
        },
        // 生成项目 关闭弹框
        closepopCreatItem() {
            this.popCreatItemVisible = false;
        },
        // 完成项目点击效验
        overItemBtn() {
            this.$confirm(
                "当前项目机会有关联的项目没有完成，无法完成！",
                "完成项目机会",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                this.handleOverItem();
            });
        },
        // 完成项目操作
        handleOverItem() {
            this.$confirm(
                "完成项目机会后项目机会信息将不可修改！是否确认完成。",
                "完成项目机会",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {});
        },
        // 继续跟进
        continueItemBtn() {
            this.$confirm("是否继续跟进此项目机会！", "继续跟进", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {});
        },
    },

    computed: {
        // 是否禁止编辑上传
        isDisableUpload() {
            let flag = false;
            if (this.readOnly) {
                flag = true;
            }
            return flag;
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.implementationManageDetails {
    padding-bottom: 0;
}
.header {
    overflow: hidden;
    line-height: 50px;
    margin-bottom: 15px;
    background: #ffffff;
}
.header .itemName {
    float: left;
    font-size: 22px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 15px;
    margin: 0;
}
.header .address {
    margin-left: 15px;
    float: left;
}
.header .el-radio-group {
    float: left;
    margin-left: 55px;
    margin-top: 5px;
}
.header .status {
    font-size: 22px;
}
// 进度条
.header .stepBox {
    width: 800px;
}
.header ::v-deep .el-step__icon {
    width: 30px;
    height: 30px;
    border-color: #d9d9d9;
    color: #d9d9d9;
    position: relative;
}
.header ::v-deep .el-step__icon:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 5px;
    left: -17px;
}
// 进行中的
.header ::v-deep .is-process .el-step__icon {
    background: #1990ff;
    border-color: #1990ff;
    color: #ffffff;
}
.header ::v-deep .el-step__title.is-process {
    color: #1990ff;
}
// 已完成的
.header ::v-deep .is-finish .el-step__icon {
    border-color: #1990ff;
    color: #1990ff;
}
.header ::v-deep .el-step.is-horizontal .el-step__line {
    top: 24px;
}
.header ::v-deep .el-step__main {
    white-space: normal;
    text-align: left;
    position: absolute;
    top: 6px;
    left: 30px;
    background: #ffffff;
    padding: 0 12px;
    color: #d9d9d9;
    cursor: pointer;
}

.el-row {
    margin-bottom: 20px;
}
.implementationManageDetails .contentWarp {
    padding: 20px;
    background: #f2f5f8;
    overflow: scroll;
}
.implementationManageDetails > .footer {
    background: #ffffff;
    padding: 8px 15px;
    width: 100%;
    height: 40px !important;
}

// 头部控制按钮
.topControlBar span {
    font-size: 24px;
    cursor: pointer;
    margin: 0 15px;
    color: #909399;
    transition: all 0.5s;
}
.topControlBar span:hover {
    transition: all 0.5s;
    color: #409eff;
}
</style>
